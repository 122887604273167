import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportRepository } from '../repository/report';
import { AutologoutService } from '../services/autologout/autologout.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-reportlss',
  templateUrl: './reportlss.component.html',
  styleUrls: ['./reportlss.component.css'],
})
export class ReportlssComponent implements OnInit {
  lang: any = 'en';
  reportData: any;
  vehInfo: any;

  dtOptions: DataTables.Settings = {};

  userid: any;
  firstname: any;
  lastname: any;
  accessToken: any;

  error_message = '';

  brand_selected: any;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private reportRepo: ReportRepository,
    private autoLogoutService: AutologoutService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      searching: false,
      ordering: false,
      pageLength: 10,
      processing: true,
      lengthChange: false,
      scrollX: true,
    };

    $('#weeklytable').DataTable().destroy();

    try {
      var currentUser = JSON.parse(localStorage.getItem('user'));

      console.info(currentUser);

      this.userid = currentUser.account.user_id;
      this.firstname = currentUser.profile.firstname_th;
      this.lastname = currentUser.profile.lastname_th;
    } catch (error) {}
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getReport();
      this.getVehInfo();
    }, 0);
  }

  public openRoute(event) {
    this.router.navigate([event]);
  }

  public signOut() {
    localStorage.setItem('user', null);
    this.openRoute('login');
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  public closeModal(id: string) {
    this.modalService.close(id);
  }

  async getReport() {
    this.reportData = null;
    let veh = (document.getElementById('cars_model') as HTMLInputElement).value;
    console.log('veh ' + veh);
    if (veh == 'def') {
      veh = '';
    }
    console.log('veh ' + veh);
    let body = {
      driverInfo : (document.getElementById("emp_no") as HTMLInputElement).value,
      plateLicense: (document.getElementById('car_no') as HTMLInputElement)
        .value,
      qrCode: (document.getElementById('qr_no') as HTMLInputElement).value,
      vehModel: veh,
      jobStatus : (document.getElementById("job_status") as HTMLInputElement).value,
    };
    console.log('body ' + body);
    try {
      var data: any = await this.reportRepo.getReportLss(
        this.accessToken,
        body
      );
      this.error_message = '';
      if (data.code == 200 || data.code == 1) {
        this.reportData = [];

        for (let i = 0; i < data.data.length; i++) {
          let bs = data.data[i];
          let ina = false;
          for (let j = 0; j < this.reportData.length; j++) {
            let item = this.reportData[j];
            if (bs.job_id == item.job_id) {
              ina = true;
            }
          }
          if (!ina) {
            this.reportData.push(bs);
          }
        }

        console.log('data ' + this.reportData);

        $('#weeklytable').DataTable().destroy().clear;

        var index = 0;
        var getDatetime = (date) => this.getDatetime(date);
        var checkNullString = (string) => this.checkNullString(string);
        var checkStatus = (any) => this.checkStatus(any);
        var t = $('#weeklytable').DataTable({
          searching: false,
          ordering: false,
          pageLength: 10,
          processing: true,
          lengthChange: false,
          scrollX: true,
          data: this.reportData,
          columns: [
            {
              title: 'No.',
              data: null,
              render: function (data, type, row) {
                index = index + 1;
                return index.toString();
              },
            },

            {
              title: 'เวลาที่รับงาน/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                return (
                  getDatetime(row.create_datetime) +
                  '<br>' +
                  checkNullString(row.firstname_th) +
                  ' ' +
                  checkNullString(row.lastname_th) +
                  '<br>' +
                  checkNullString(row.phone_number) +
                  ''
                );
              },
            },
            {
              title: 'เวลาที่เสร็จงาน/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                return (
                  getDatetime(row.create_datetime) +
                  '<br>' +
                  checkNullString(row.firstname_th) +
                  ' ' +
                  checkNullString(row.lastname_th) +
                  '<br>' +
                  checkNullString(row.phone_number) +
                  ''
                );
              },
            },

            {
              title: 'สถานะปัจจุบัน',
              data: null,
              render: function (data, type, row) {
                return checkStatus(row.job_status);
              },
            },

            {
              title: 'รหัสพนักงาน',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.emp_code);
              },
            },
            {
              title: 'พนักงานขับรถ',
              data: null,
              render: function (data, type, row) {
                return (
                  checkNullString(row.firstname_th) +
                  ' ' +
                  checkNullString(row.lastname_th) +
                  '<br>' +
                  checkNullString(row.phone_number) +
                  ''
                );
              },
            },

            {
              title: 'รุ่นรถ',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.veh_model_name);
              },
            },

            {
              title: 'หมายเลขรถ',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.veh_code);
              },
            },

            {
              title: 'ทะเบียน',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.license_plate_no);
              },
            },
            { title: 'QR Code ใบงาน', data: 'qrcode' },
            { title: 'Job No.', data: 'job_code' },
            {
              title: 'ประเภทงาน',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.job_type);
              },
            },
            {
              title: 'Pickup Datetime',
              data: null,
              render: function (data, type, row) {
                return getDatetime(row.pickup_datetime);
              },
            },
            {
              title: 'ต้นทาง',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.place_id);
              },
            },
            {
              title: 'ปลายทาง',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.force_destination);
              },
            },
          ],
        });
      } else {
        console.log('error ');
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }

  public getDatetime(date) {
    var datetime = '';
    if (date) {
      try {
        const format1 = 'YYYY-MM-DD HH:mm:ss';
        var d = new Date(date);
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yy = d.getFullYear();
        var hh = d.getHours().toString();
        if (parseInt(hh) < 10) {
          hh = '0' + hh;
        }
        var m = d.getMinutes().toString();
        if (parseInt(m) < 10) {
          m = '0' + m;
        }
        datetime = dd + '/' + mm + '/' + yy + '   ' + hh + ':' + m + ' น.';
      } catch (error) {
        console.log(error);
        datetime = 'no date time';
      }
    }
    return datetime;
  }

  public checkNullString(string) {
    var resturnString = '';
    if (string) {
      resturnString = string;
    }
    return resturnString;
  }

  public checkStatus(status: any) {
    let status_string = '';
    let color_string = '';
    // 0 = wait for start,1 = starting,2 = go to pickup,3 = waiting for psg, 4 = psg on car ,9= end job
    try {
      if (status == 0) {
        status_string = 'รอเริ่มงาน';
        color_string = '#F36D96';
      } else if (status == 1) {
        status_string = 'เริ่มงาน';
        color_string = '#9A9A9A';
      } else if (status == 2) {
        status_string = 'ออกเดินทาง';
        color_string = '#72266E';
      } else if (status == 3) {
        status_string = 'รอรับผู้โดยสาร';
        color_string = '#2759A4';
      } else if (status == 4) {
        status_string = 'ผู้โดยสารขึ้นรถ';
        color_string = '#F9B952';
      } else if (status == 8) {
        status_string = 'ถึงเขตสุวรรณภูมิ';
        color_string = '#2759A4';
      } else if (status == 9) {
        status_string = 'จบงาน';
        color_string = '#00A1F7';
      }
    } catch (error) {}
    if (status_string != '') {
      return (
        "<button style='border-radius: 2px;background-color: " +
        color_string +
        ';' +
        'border: none;' +
        'color: white;' +
        'padding: 5px;' +
        'text-align: center;' +
        'text-decoration: none;' +
        'display: inline-block;' +
        'font-size: 16px;' +
        'margin: 4px 2px;' +
        "cursor: pointer;'>" +
        status_string +
        '</button>'
      );
    } else {
      return '';
    }
  }

  async getVehInfo() {
    this.reportData = null;
    let body = {};
    console.log('body ' + body);
    try {
      var data: any = await this.reportRepo.getVehInfo(this.accessToken, body);
      this.error_message = '';
      if (data.code == 200) {
        this.vehInfo = data.data.vehInfo;
      } else {
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }

  selectOption(value) {
    //getted from event
    console.log(value);
    //getted from binding
    console.log(this.brand_selected);
  }
}
