<!-- header -->
<div class="container-fluid" style="background-color: #EFEDED;">
    <div class="row justify-content-between">
        <div>
            <img src="../../assets/images/aot_logo_new.png" class="img-fluid" style="max-height: 75px;margin-left: 10px;"/>
        </div>
        <div class="align-items-center" style="display: flex;">
            <div id="" style="margin-right: 10px;">{{userid}}</div>
            <div id="firstname" style="margin-right: 10px;">{{firstname}}</div>
            <div is="lastname" style="margin-right: 10px;">{{lastname}}</div>
            <div style="margin-right: 10px;" (click)="openModal('modal_logout')">
                <img src="../../assets/images/icon_logout.png" class="img-fluid" style="max-height: 40px;"/>
            </div>
        </div>
    </div>
</div>

<!-- menu -->
<div class="container-fluid">
    <div class="align-items-center" style="display: flex;">
        <img src="../../assets/images/icon_back.png" class="img-fluid" style="max-height: 45px;margin: 20px;" (click)="openRoute('home')"/>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('getjob')">รับงาน </button>
        <button class="btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;margin: 10px;">เสร็จงาน </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportlss')">รายงาน LSS </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportvip')">รายงาน VIP </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportmaintain')">รายงานการซ่อม </button>
    </div>
</div>

<!--<div class="container-fluid">
    <div [hidden]="!error_message" style="margin-right: 10px;color: #F06464;">{{error_message}}</div>
</div>-->
<div class="container-fluid" [hidden]="!error_message" style="margin-right: 10px;color: #EFEDED; background-color: #F06464; padding: 10px;margin-bottom: 10px;">
    ทำรายการไม่สำเร็จ เนื่องจาก {{error_message}}
</div>
<div class="container-fluid" [hidden]="!success_message" style="margin-right: 10px;color: #EFEDED; background-color:#04AA6D; padding: 10px;margin-bottom: 10px;">
    {{success_message}}
</div>

<!-- content -->
<div class="container-fluid" style="height: 80%;">
    <div class="justify-content-center align-items-start" style="display: flex;min-height: 100%;">
        <!-- detail -->
        <div class="col">
            <div>
                <div style="border-radius: 10px;background-color: #F4F4F4;">
                    <div class="align-items-center" style="background-color: #25C6E4;color: white;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;">ข้อมูลรถ</div>
                    <div class="container-fluid" style="padding: 10px;text-align: center;">
                        <div class="align-items-center" *ngIf="!car_info_data  && !submit_car">กรุณาแสกน QRcode</div>
                        <div class="align-items-center" *ngIf="!car_info_data && submit_car">
                            {{error_message_car}}
                          </div>                       
                            <div *ngIf="car_info_data" class="container-fluid" style="display: flex;">
                            <div class="col" style="background-color: #E3E4E4;border-radius: 10px;">
                                <img src="../../assets/images/car_img_holder.png" class="img-fluid"/>
                            </div>
                            <div class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">รุ่นรถ :</div>
                                    <div class="col">{{car_info_data.vehModel.veh_model_name}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">หมายเลขรถ :</div>
                                    <div class="col">{{car_info_data.vehInfo.veh_code}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">ทะเบียนรถ :</div>
                                    <div class="col">{{car_info_data.vehInfo.license_plate_no}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 50px;"></div>
            <div>
                <div style="border-radius: 10px;background-color: #F4F4F4;">
                    <div class="align-items-center" style="background-color: #25C6E4;color: white;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;">ข้อมูลพนักงาน</div>
                    <div class="container-fluid" style="padding: 10px;text-align: center;">
                        <div class="align-items-center" *ngIf="!emp_info_data  && !submit_car">กรุณาแสกน QRcode</div>
                        <div class="align-items-center" *ngIf="!emp_info_data && submit_car">
                            {{error_message_emp}}
                          </div>  
                       <!--- <div class="align-items-center" *ngIf="!emp_info_data" class="align-items-center">กรุณาแสกน QRcode</div>-->
                        <div *ngIf="emp_info_data" class="container-fluid" style="display: flex;">
                            <div class="col" style="background-color: #E3E4E4;border-radius: 10px;">
                                <img src="../../assets/images/emp_img_holder.png" class="img-fluid"/>
                            </div>
                            <div class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">รหัสพนักงาน :</div>
                                    <div class="col">{{emp_info_data.emp_id}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">ชื่อ - นามสกุล :</div>
                                    <div class="col">{{emp_info_data.firstname_th}} {{emp_info_data.lastname_th}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">เบอร์โทรศัพท์ :</div>
                                    <div class="col">{{emp_info_data.phone_number}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 50px;"></div>
            <div>
                <div style="border-radius: 10px;background-color: #F4F4F4;">
                    <div class="align-items-center" style="background-color: #25C6E4;color: white;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;">ข้อมูลใบงาน</div>
                    <div class="container-fluid" style="padding: 10px;text-align: center;">
                        <div class="align-items-center" *ngIf="!job_info_data  && !submit_car">กรุณาแสกน QRcode</div>
                        <div class="align-items-center" *ngIf="!job_info_data && submit_car">
                            {{error_message_job}}
                          </div> 
                      <!--  <div class="align-items-center" *ngIf="!job_info_data" class="align-items-center">กรุณาแสกน QRcode</div>-->
                        <div *ngIf="job_info_data" class="container-fluid" style="display: flex;">
                            <div *ngIf="job_info_data.type == 'lss'" class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">Job No :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellJob.job_code}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">Print DateTime :</div>
                                    <div class="col" style="text-align: left;">{{getDatetime(job_info_data.sellJob.print_datetime)}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">รหัส QRcode :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellJob.qrcode}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">ประเภทการเดินทาง :</div>
                                    <div class="col" style="text-align: left;">
                                        <button class="btn" [ngStyle]="{'background-color':mapLssBookingByType().color }" style="color: white;border-radius: 10px;min-width: 55px;">{{mapLssBookingByType().type}}</button>
                                        <button class="btn" [ngStyle]="{'background-color':mapLssJobType().color }" style="color: white;border-radius: 10px;min-width: 55px;margin-left: 10px;">{{mapLssJobType().type}}</button>
                                    </div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">รุ่นรถ :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.vehModel.veh_model_name}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">ราคา(บาท) :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellJob.job_net_price}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">เวลาที่เดินทาง :</div>
                                    <div class="col" style="text-align: left;">{{getDatetime(job_info_data.sellJob.pickup_datetime)}}</div>
                                </div>
                                <!-- origin อยู่ใน sell_job_detail เป็น row ที่ sequence = 1 คอลัมน์ Force Name Destination นะคะ -->
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">สถานที่ต้นทาง :</div>
                                    <div class="col" style="text-align: left;">{{findOrigin()}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">สถานที่ปลายทาง :</div>
                                    <div class="col" style="text-align: left;">{{findDest()}}</div>
                                </div>
                            </div>

                            <div *ngIf="job_info_data.type == 'vip'" class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">Job No :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellJob.job_code}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">Create DateTime :</div>
                                    <div class="col" style="text-align: left;">{{getDatetime(job_info_data.sellBooking.create_datetime)}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">รหัส QRcode :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellJob.job_code}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">ประเภทการเดินทาง :</div>
                                    <div class="col" style="text-align: left;">
                                        <button class="btn" style="background-color: #b93b2a;color: white;border-radius: 10px;min-width: 55px;">VIP</button>
                                        <button class="btn" [ngStyle]="{'background-color':mapVipWorkType().color }" style="color: white;border-radius: 10px;min-width: 55px;margin-left: 10px;">{{mapVipWorkType().type}}</button>
                                    </div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">รุ่นรถ :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.vehModel.veh_model_name}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">ราคา(บาท) :</div>
                                    <div class="col" style="text-align: left;">{{job_info_data.sellBooking.booking_net_price}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">เวลาที่เดินทาง :</div>
                                    <div class="col" style="text-align: left;">{{getDatetime(job_info_data.sellJob.job_pickup_datetime)}}</div>
                                </div>
                                <!-- origin อยู่ใน sell_job_detail เป็น row ที่ sequence = 1 คอลัมน์ Force Name Destination นะคะ -->
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">สถานที่ต้นทาง :</div>
                                    <div class="col" style="text-align: left;">{{findOrigin()}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col" style="text-align: right;">สถานที่ปลายทาง :</div>
                                    <div class="col" style="text-align: left;">{{findDest()}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- input -->
        <div class="col">
            <div *ngIf="lang == 'en'" class="col">
                <div *ngIf="user_agent == 'mobile'" class="container-fluid">
                    <div *ngIf="!camera_init" style="text-align: center;" (click)="initCamera()">
                        <img src="../../assets/images/icon_scan_qr.png" class="img-fluid" />
                        <div>แตะเพื่อแสกน QRcode</div>
                    </div>
                    <div [hidden]="!camera_init">
                        <div>QRCode Scanner</div>
                        <div class="" id="loadingMessage">🎥 Unable to access video stream (please make sure you have a webcam enabled)</div>
                        <div class="row justify-content-center">
                            <canvas id="canvas" hidden style="max-width: 250px;max-height: 250px;"></canvas>
                        </div>
                        <div id="output" hidden>
                        <div id="outputMessage">No QR code detected.</div>
                        <div hidden>
                            <b>Data:</b> <span id="outputData"></span>
                        </div>
                        </div>
                        <audio id="beepsound" controls hidden>
                        <source src="../../assets/sound/scanner-beeps-barcode.mp3" type="audio/mpeg">
                        Your browser does not support the audio tag.
                        </audio>  
                    </div>
                </div>
            </div>
            <div class="col" style="display: flex;padding: 0px;margin-top: 10px;">
                <div class="col-4">
                    <div>รหัส QR ใบงาน :</div>
                </div>
                <div class="col-8" style="padding: 0px;">
                    <input type="text" class="form-control" name="qr_no" id="qr_no" (keyup.enter)="onKeyUpEvent($event , '')" (input)="valueChange($event , '')" (focus)="onFocus()"/>
                </div>
                <button type="button"  *ngIf="job_info_data" (click)="clearJobInfo()" class="btn btn-danger">x</button>
            </div>
            <div class="col" style="display: flex;padding: 0px;margin-top: 10px;">
                <div class="col-4"></div>
               <!-- <button [disabled]="!qr_no_text" class="col-8 btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;" (click)="finishJob()">เสร็จงาน </button>-->
               <button [disabled]="!job_info_data || !emp_info_data || !car_info_data" class="col-8 btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;" (click)="finishJob()">เสร็จงาน </button>
            </div>
        </div>
    </div>
</div>

<!-- logout dialog  -->
<jw-modal id="modal_logout">
    <div class="container">
      <h1>Dispatch Website</h1>
      <div style="height: 1px;background-color: black;"></div>
      <div style="min-height: 100px;">ออกจากระบบ</div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <button class="col btn btn-lg btn-block" (click)="signOut();" style="background-color: #F0F0F7;color: #0B499C;border-radius: 0px;border-bottom-left-radius: 10px;">
                ตกลง
            </button>
            <button class="col btn btn-lg btn-block" (click)="closeModal('modal_logout');" style="background-color: #EDDBDB;color: #F06464;;border-radius: 0px;border-bottom-right-radius: 10px;margin: 0px;">
              ยกเลิก
            </button>
        </div>
    </div>
</jw-modal>

<jw-modal id="modal_finishjob_success">
    <div class="container">
      <h1>เสร็จงาน</h1>
      <div style="height: 1px;background-color: black;"></div>
      <div style="min-height: 100px;">สำเร็จ</div>
    </div>
    <button class="btn btn-lg btn-block btn-secondary" (click)="finishJobSuccess();" style="background-color: #F0F0F7;color: #0B499C;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
      ตกลง
    </button>
</jw-modal>